'use client'

import React from 'react'
import {useSearchParams} from 'next/navigation'

import {usePathname} from '../app/i18n/routing'

export function useReportAnalytics(reportAnalyticsFn) {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  React.useEffect(() => {
    const url = `${pathname}?${searchParams}`
    reportAnalyticsFn(url)
  }, [pathname, searchParams])
}