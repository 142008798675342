'use client'

import React from 'react'
import {css, Global, useTheme} from '@emotion/react'
import Scrollbars from 'react-custom-scrollbars-2'

import {useReportAnalytics} from '../../../hooks/useReportAnalytics'
import * as gtag from '../../../utils/gtag'
import {CssBaseline} from '@mui/material'

export default function BaseDesktopLayout({navbar, children}) {
  const theme = useTheme()
  const styles = {
    // global
    global: css`
      body {
        font-family: "Roboto", "Helvetica", "Microsoft JhengHei", "Arial", "sans-serif";
      }
    `,

    // base
    root: css`
      position: absolute;
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;
    `,

    content: css`
      flex: 1;
      position: relative;
      display: flex;
      overflow: hidden;
    `,

    container: css`
      flex: 1;
      position: relative;

      background: ${theme.palette.primary.lightest};
      color: ${theme.palette.onSurface.dark};
    `,

    main: css`
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      padding: 32px 32px 16px;

      @media (max-width: ${theme.breakpoints.sm}px) {
        padding: 16px;
      }
    `,
  }

  useReportAnalytics(gtag.pageview)

  return (
    <>
      <CssBaseline/>

      <Global
        styles={styles.global}
      />

      <div css={styles.root}>
        <div css={styles.content}>
          {navbar}
          <Scrollbars css={styles.container} universal autoHide>
            <main id={'base-container'} css={styles.main}>
              {children}
            </main>
          </Scrollbars>
        </div>
      </div>
    </>
  )
}
