'use client'

import React from 'react'
import {css, Global, useTheme} from '@emotion/react'
import Scrollbars from 'react-custom-scrollbars-2'
import Title from '../../modules/Title'
import {useTranslations} from 'next-intl'
import {useReportAnalytics} from '../../../hooks/useReportAnalytics'
import * as gtag from '../../../utils/gtag'
import {CssBaseline} from '@mui/material'


export default function BaseMobileLayout({children}) {
  const theme = useTheme()
  const styles = {
    // global
    global: css`
      body {
        font-family: "Roboto", "Helvetica", "Microsoft JhengHei", "Arial", "sans-serif";
        background: ${theme.palette.primary.lightest}; // 讓手機下拉時背景不是白色
      }
    `,

    // base
    root: css`
      position: absolute;
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;
    `,

    header: css`
      width: 100%;
      height: 64px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      background: ${theme.palette.primary.dark};
      padding-left: 16px;
      padding-right: 16px;
    `,

    content: css`
      flex: 1;
      position: relative;
      display: flex;
      overflow: hidden;
    `,

    container: css`
      background: ${theme.palette.primary.lightest};
      color: ${theme.palette.onSurface.dark};

      flex: 1;
      position: relative;
    `,

    main: css`
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      padding: 16px;
    `,
  }

  useReportAnalytics(gtag.pageview)

  const t = useTranslations()

  return (
    <>
      <CssBaseline/>

      <Global
        styles={styles.global}
      />

      <div css={styles.root}>
        <header css={styles.header} data-nosnippet={true}>
          <Title title={t('site-name')}/>
        </header>
        <div css={styles.content}>
          <Scrollbars css={styles.container} universal autoHide>
            <main id={`base-container`} css={styles.main}>
              {children}
            </main>
          </Scrollbars>
        </div>
      </div>
    </>
  )
}
