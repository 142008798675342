import React from 'react'
import createCache from '@emotion/cache'
import {CacheProvider} from '@emotion/react'
import {useServerInsertedHTML} from 'next/navigation'

// ref: https://github.com/mui/material-ui/blob/master/packages/mui-material-nextjs/src/v13-appRouter/appRouterV13.tsx

export default function MuiAppRouterCacheProvider({children}) {
  const [registry] = React.useState(() => {
    const cache = createCache({key: 'mui'})
    cache.compat = true

    const prevInsert = cache.insert
    let inserted = []

    // Override the insert method to support streaming SSR with flush().
    cache.insert = (...args) => {
      const [selector, serialized] = args
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push({
          name: serialized.name,
          isGlobal: !selector,
        })
      }
      return prevInsert(...args)
    }

    const flush = () => {
      const prevInserted = inserted
      inserted = []
      return prevInserted
    }

    return {cache, flush}
  })

  useServerInsertedHTML(() => {
    const inserted = registry.flush()
    if (inserted.length === 0) {
      return null
    }

    let styles = ''
    let dataEmotionAttribute = registry.cache.key

    const globals = []

    inserted.forEach(({name, isGlobal}) => {
      const style = registry.cache.inserted[name]

      if (typeof style === 'string') {
        if (isGlobal) {
          globals.push({name, style})
        } else {
          styles += style
          dataEmotionAttribute += ` ${name}`
        }
      }
    })

    return (
      <>
        {globals.map(({name, style}) => (
          <style
            key={name}
            data-emotion={`${registry.cache.key}-global ${name}`}
            dangerouslySetInnerHTML={{__html: style}}
          />
        ))}
        {styles && (
          <style
            data-emotion={dataEmotionAttribute}
            dangerouslySetInnerHTML={{__html: styles}}
          />
        )}
      </>
    )
  })

  return <CacheProvider value={registry.cache}>{children}</CacheProvider>
}
