import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/components/layouts/BaseLayout/BaseDesktopLayout.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/components/layouts/BaseLayout/BaseMobileLayout.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/components/layouts/BaseLayout/ClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/client/script.js");
